<template>
  <HeroBanner
    :title="content.config?.title?.value"
    :button-text="content.config?.buttonText?.value"
    :button-text-link="content.config?.buttonTextLink?.value"
    :is-compact="content.config?.isCompact?.value"
    :text-horizontal-alignment="content.config?.textHorizontalAlignment?.value"
    :media="updatedMedia"
    :text-content="textContentWithProperInternalLinks"
    :should-display-logo="content?.config?.shouldDisplayLogo?.value"
    :logo-color="content?.config?.logoColor?.value"
  />
</template>

<script setup lang="ts">
import HeroBanner from "@/components/molecules/HeroBanner";
import { type CmsElement } from "@/types/cms";
import { type Media } from "@shopware-pwa/types";
import { type HorizontalAlignment } from "@/types";
import { mediaUpdater } from "@/utils/mediaUpdater";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";

const { mapHTMLWithLinks } = useInternalLinkMapper();

const props = defineProps<{
  content: CmsElement<{
    title: string;
    buttonText: string;
    buttonTextLink: string;
    isCompact: boolean;
    textHorizontalAlignment: HorizontalAlignment;
    media: Media;
    textContent: string;
    shouldDisplayLogo?: boolean;
    logoColor?: string;
  }>;
}>();
const updatedMedia = props.content.data as Media;

const textContentWithProperInternalLinks = await mapHTMLWithLinks(
  props.content?.config?.textContent?.value,
);
</script>
